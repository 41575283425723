import React, { FC, memo, useMemo } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import Image from 'next/image'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { removeNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { successRegisterOnlineNotStartedEventNoticeId } from '../constants'
import noticeSuccessRegister from '../assets/img__notice_successRegister.png'
import data from '../data.json'
import { IEventsPageRegisterOnlineNotice } from '../types'

/** Нотис при успешной регистрации на мероприятие (не стартовавшее) */
const EventsPageRegisterNotStartedEventNotice: FC<IEventsPageRegisterOnlineNotice> = (
  props
) => {
  const { id, open, isHybrid } = props
  const noticeNata = useMemo(() => data.successRegister.onlineLater, [])

  return (
    <Notice
      id={successRegisterOnlineNotStartedEventNoticeId + id}
      hideProgress
      open={open}
      color='blue'
      width='518px'
      onClose={() => {
        removeNoticeCookie({ id: successRegisterOnlineNotStartedEventNoticeId + id })
      }}
    >
      <Grid spacing='medium' nowrap alignItems='flex-end'>
        <GridCol width='auto' shrink='initial'>
          <Heading h3={noticeNata.title} spacing='xxsmall' />

          <Text>{!isHybrid ? noticeNata.description : noticeNata.hybridDescription}</Text>
        </GridCol>

        <GridCol width={`${84 + 16}px`}>
          <Image src={noticeSuccessRegister} alt='' width={84} height={84} />
        </GridCol>
      </Grid>
    </Notice>
  )
}

export default memo(EventsPageRegisterNotStartedEventNotice)
