import React, { FC, Fragment, memo, useMemo } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import Image from 'next/image'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import TgIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import { Description } from '@ucheba/ui/components/Description/desktop'
import styled from 'styled-components'
import { removeNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import LeftImage from '../assets/img__notice_desktop.png'
import { IEventNoticeNotAllowedMessage } from '../types'
import data from '../data.json'
import { notAllowedMessegerNoticeId } from '../constants'

const GridColST = styled(GridCol)`
  display: flex;
  align-items: flex-end;
`

/** Нотис при успешной регистрации на мероприятие (не стартовавшее) */
const EventNoticeNotAllowedMessage: FC<IEventNoticeNotAllowedMessage> = (props) => {
  const { id, open, isHybrid, isOnline, onClose } = props

  const noticeData = useMemo(() => {
    if (isHybrid) {
      return data.hybrid
    }
    if (isOnline) {
      return data.online
    }
    return data.offline
  }, [isHybrid, isOnline])

  return (
    <Notice
      id={notAllowedMessegerNoticeId + id}
      hideProgress
      open={open}
      color='navy'
      onClose={() => {
        removeNoticeCookie({ id: notAllowedMessegerNoticeId + id })
      }}
    >
      <Heading h3={noticeData.title} />

      <Grid spacing='uxsmall' justifyContent='space-between'>
        <GridCol width='344px'>
          <Description description={noticeData.description} />

          <Spacing spacing='small' />

          {noticeData.buttons.map((button) => {
            return (
              <Fragment key={button.id}>
                <Button
                  color='gold'
                  target='_blank'
                  href={button.href}
                  StartIcon={button.id === 'vk' ? <VkIcon /> : <TgIcon />}
                  onClick={() => {
                    onClose()
                    // отправляем аналитику клика-перехода в яндекс-метрику
                    analyticEvent({
                      targets: [EAnalyticEventTargets.ym, EAnalyticEventTargets.ur],
                      eventName: EAnalyticEvents.vkNoticeClickLead,
                      section: EAnalyticSections.eventNoticeNotAllowedMessages,
                    })
                  }}
                >
                  {button.text}
                </Button>
                <Spacing spacing='small' />
              </Fragment>
            )
          })}
        </GridCol>

        <GridColST width='114px'>
          <Image src={LeftImage} width={114} height={166} alt='' />
        </GridColST>
      </Grid>
    </Notice>
  )
}

export default memo(EventNoticeNotAllowedMessage)
