import React, { FC, Fragment, memo, useCallback } from 'react'
import { useRouter } from 'next/router'

import { useAllowedMessangers } from '@ucheba/utils/hooks/useAllowedMessangers'
import { EMessengerType } from '@ucheba/store/profile/types'
import { Banner } from '../../Banner/desktop'

import { IEventsListProps } from '../types'
import { EventsListItem } from './EventsListItem'
import SC from '../base/EventsList.styles'
import { pathsForBanner } from '../contants'
import { useFinishedEvents } from '../bll'

const { Block } = SC

/** Список событий */
const EventsList: FC<IEventsListProps> = (props) => {
  const {
    isBanner = false,
    items,
    onClickAction,
    actionsPending,
    href,
    needVk,
    linkReg,
    params,
  } = props

  const router = useRouter()
  const isPathForBanner = pathsForBanner.includes(router.pathname)

  const isFirstFinishedEvent = useFinishedEvents(items)

  const isAllowedMess = useAllowedMessangers([EMessengerType.vk, EMessengerType.telegram])

  const showBanner = useCallback(
    (index: number) => {
      if (isBanner && items && items?.length < 3) {
        return items.length - 1 === index
      }
      if (isBanner && items && items?.length > 9) {
        return index === 2 || index === 9
      }
      if (isBanner && items) {
        return index === 2
      }
      return false
    },
    [items, isBanner]
  )

  return (
    <Block>
      {items.map((event, index, array) => {
        const isDivider = index !== 2 && isBanner

        if (array.length < 3 && index === array.length - 1 && isPathForBanner) {
          return (
            <Fragment key={event.id}>
              <EventsListItem
                isDivider
                {...event}
                onClickAction={onClickAction}
                isActionPending={Boolean(actionsPending?.includes(event.id))}
                pathname={href}
                needVk={needVk}
                isFinished={event.isFinished}
                isFinishedLine={isFirstFinishedEvent(index)}
                isAllowedMess={isAllowedMess}
              />

              {showBanner(index) && (
                <li>
                  <Banner id={2} extraParams={params} />
                </li>
              )}
            </Fragment>
          )
        }

        if (index === 2 && isPathForBanner) {
          return (
            <Fragment key={event.id}>
              <EventsListItem
                isDivider
                {...event}
                onClickAction={onClickAction}
                isActionPending={Boolean(actionsPending?.includes(event.id))}
                pathname={href}
                needVk={needVk}
                isFinishedLine={isFirstFinishedEvent(index)}
                linkReg={linkReg}
                isAllowedMess={isAllowedMess}
              />

              {showBanner(index) && (
                <li>
                  <Banner id={2} extraParams={params} />
                </li>
              )}
            </Fragment>
          )
        }

        return (
          <Fragment key={event.id}>
            <EventsListItem
              isDivider
              {...event}
              onClickAction={onClickAction}
              isActionPending={Boolean(actionsPending?.includes(event.id))}
              pathname={href}
              needVk={needVk}
              isFinishedLine={isFirstFinishedEvent(index)}
              linkReg={linkReg}
              isAllowedMess={isAllowedMess}
            />

            {showBanner(index) && (
              <li>
                <Banner id={2} extraParams={params} />
              </li>
            )}
          </Fragment>
        )
      })}

      {isBanner && items.length === 0 && (
        <li>
          <Banner id={2} extraParams={params} />
        </li>
      )}
    </Block>
  )
}

export { EventsList }
export default memo(EventsList)
