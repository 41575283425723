import { FC, memo, useMemo } from 'react'
import RegDialog from '../../RegDialog/desktop'
import { EQueueElements } from '../../RegDialog/types'
import { IParentFormDialogSuccessProps } from '../types'
import { socialText } from '../constants'

const ParentFormDialogSuccess: FC<IParentFormDialogSuccessProps> = (props) => {
  const { open, onClose, isInstitution, description } = props

  const descriptionText = useMemo(() => {
    if (description) {
      return description
    }

    return isInstitution ? socialText.description2 : socialText.description
  }, [description, isInstitution])

  return (
    <RegDialog
      title={socialText.title}
      description={descriptionText}
      open={open}
      onClose={onClose}
      params={{ templateId: 26 }}
      queue={[EQueueElements.tg, EQueueElements.vk]}
    />
  )
}

export default memo(ParentFormDialogSuccess)
